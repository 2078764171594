import React from "react";
class Footer extends React.Component {
  render() {
    return(
      <div className ="footer-margin">
        <div className="container">
          <footer className="row justify-content-between align-items-center py-3">
            <div className="col-md-6 d-flex flex-col align-items-center flex-wrap">
              <div className="flex-wrap">
                <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/logo-text1.png'} width="auto" height="32"/>
                </a>
                <span className="mb-3 mb-md-0" style={{color:'#313743',fontWeight: '700',fontSize: '17px',paddingTop: '15px',paddingLeft: '15px'}}>
                  {/* &copy; 2023 zedscan.net, Inc */}
                    Power by ZEDXION Smart Chain
                  </span>
              </div>
              <div style={{color:'#313743',width:'100%',marginTop:'30px',marginBottom:'30px',fontSize:'14px'}}>
                ZedStake involves holding and voting to earn rewards while securing the network's decentralized consensus.
              </div>
              <div className="flex flex-wrap flex-wrap-footer" style={{color:'#313743',marginBottom:'30px',fontSize:'14px',gap: '20px',display: 'flex'}}>
                <a href="https://zed-pay.com/" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/neobank_icon.svg'}  style={{marginRight:'10px'}}/>
                  NeoBank(ZedPay)
                </a>
                <a href="https://www.zedxion.io/en_US" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/zedcex_icon.svg'}  style={{marginRight:'10px'}}/>
                  CEX1(ZEDXION)
                </a>
                <a href="https://www.zedcex.com/en_US/market" target="_blank" className="flex flex-wrap CEX2" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/zedcex_icon.svg'}  style={{marginRight:'10px'}}/>
                  CEX2(ZedCex)
                </a>
                <a href="https://zedscan.net/" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/logoZedScan.png'} width="17" height="18" style={{marginRight:'10px'}}/>
                  ZedScan
                </a>
                <a href="https://zeddex.com/" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/logoZedDex.png'} width="21" height="17" style={{marginRight:'10px'}}/>
                  ZedDex
                </a>
                <a href="https://bridge.zedxion.com/" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/logoZedStake.png'} width="17" height="17" style={{marginRight:'10px'}}/>
                  ZedBridge
                </a>
                <a href="https://token.zedxion.com" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/logoZedToken.png'} width="21" height="15" style={{marginRight:'10px'}}/>
                  ZedToken
                </a>
                <a href="https://airdrop.zedxion.com/" target="_blank" className="flex flex-wrap" style={{color:'#313743'}}>
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/logoZedDrop.png'} width="21" height="15" style={{marginRight:'10px'}}/>
                  ZedDrop
                </a>
              </div>
              <div className="flex flex-wrap" style={{color:'#313743',width: '100%'}}>
                <a href="https://www.facebook.com/zedxioncoin" target="_blank">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra.png'} />
                </a>
                <a href="https://twitter.com/zedxionc" target="_blank" className="ms-3">
                 <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra1.png'} />
                </a>
                <a href="https://www.instagram.com/zedxion/" target="_blank" className="ms-3">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra2.png'} />
                </a>
                <a href="#" target="_blank" className="ms-3">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra3.png'} />
                </a>
                <a href="#" target="_blank" className="ms-3">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra4.png'} />
                </a>
                <a href="https://medium.com/subscribe/@zedxion_exchange" target="_blank" style={{ marginTop: '4px' }} className="ms-3">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra5.png'} />
                </a>
                <a href="https://www.linkedin.com/company/zedxion-exchnage-crypto" target="_blank" className="ms-3">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra6.png'} />
                </a>
                <a href="https://github.com/Zedxioncryptocurrency" target="_blank" className="ms-3">
                  <img src={process.env.PUBLIC_URL +'/assets/images/logo/chakra7.png'} />
                </a>
              </div>
              <div className="flex flex-wrap" style={{color:'#313743',width: '100%',marginTop:'30px',fontSize:'13px'}}>
                ZEDXION Chain © 2024      |    
                <a href="https://docs.zedscan.net/zedxion-whitepaper/" target="_blank" className="ml-[5px]" style={{color:'#1FC7D4',marginLeft:'5px'}}>
                  WhitePaper
                </a>
              </div>
            </div>

            {/* <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3"><a className="text-success" style={{color:'#000000'}} href="#"><i className="fa fa-twitter"></i></a></li>
              <li className="ms-3"><a className="text-success" style={{color:'#000000'}} href="#"><i className="fa fa-instagram"></i></a></li>
              <li className="ms-3"><a className="text-success" style={{color:'#000000'}} href="#"><i className="fa fa-telegram"></i></a></li>
            </ul> */}
            <div className="col-md-6 row" style={{marginTop:'65px'}}>
              <div className="col-md-4 menu-ul-footer">
                <a href="#" style={{color:'#313743',fontWeight:'600'}} className="text-[16px]">
                  Company
                </a>
                <a href="#" style={{color:'#313743',fontWeight:'500'}} className="text-[13px]">
                  Delegate to ZedScan
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]" style={{marginBottom: '3px'}}>
                  <p style={{background: '#0CC1E0',borderRadius:'13px',padding:'4px 8px',width: '70px', fontSize:'13px', textAlign:'center', color:'#fff'}}>Staking</p>
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Brand Assets
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Contact Us
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Terms & Privacy
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Bug Bounty
                </a>
              </div>
              <div className="col-md-4 menu-ul-footer">
                <a href="#" style={{color:'#313743',fontWeight:'600'}} className="text-[16px]">
                  Community
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  API Documentation
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Knowledge Base
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Network Status
                </a>
                <a href="https://docs.zedscan.net/general/learn-about-the-fuse-platform" style={{color:'#313743'}} className="text-[13px]">
                  Learn ZEDSCAN
                </a>
                <a href="#">
                
                </a>
              </div>
              <div className="col-md-4 menu-ul-footer">
                <a href="#" style={{color:'#313743',fontWeight:'600'}} className="text-[16px]">
                  Products & Services
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Advertise
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Explorer-as-a-Service (EaaS)
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  API Plans
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Priority Support
                </a>
                <a href="#" style={{color:'#313743'}} className="text-[13px]">
                  Blockscan
                </a>
              </div>
            </div>
          </footer>
        </div>
      
      </div>
    );
  }
}
export default Footer;