import React, { useState } from "react";
import StakePools from "../components/List/StakePools";
import { list } from "../data/StakingPoolLists";

function Home() {
  const [poolList, setPoolList] = useState(list);
  const discoverStyle = {
    background:"#1FC7D4",
    color:"#FFFFFF",
    borderRadius:'30px !important'
  };

  return (
    <>
      <div className="container px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-lg-6">
            <div className="head-bar" />
            <h1 className="display-5 lh-1 my-3 title-text fw-600">
              Just Stake ZEDX To Earn 
            </h1>
            <p className="lead py-3" style={{color:'#676767'}}>
              ZEDXION Coin's stake in an advanced Delegated Proof of Stake (DPoS) blockchain enables users to engage in profit-sharing from the larger ZEDXION project.

            </p>
            <p className="lead py-3" style={{color:'#676767'}}>
              ZEDXION, a pioneering NeoBanking Blockchain, boasts a staggering Billions Trading Volume (24h) and holds over Billions in Financial Reserves (Total Assets).
            </p>
            <p className="lead py-3" style={{color:'#676767'}}>
              ZEDXION has a comprehensive suite of projects lined up, including ZedPay, ZEDXION Exchange, ZedCex Exchange, ZedDex, ZedBridge, ZedWallet, ZedStake, ZedToken, and ZedDrop. With a diverse range of offerings and a focus on low-risk investment, ZEDXION has the potential for success, especially in executing plans effectively during their dream business trip.
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              <button
                type="button"
                className="btn btn-lg px-4 me-md-2 rounded"
                style={discoverStyle}
              >
                Discover
              </button>
            </div>
          </div>
          <div className="col-sm-8 col-lg-6">
            <img
              src="assets/images/misc/hero.png"
              className="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              width="700"
              height="500"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <StakePools poolList={poolList} />
      </div>
    </>
  );
}

export default Home;
