import React from "react";
import '../../styles/StyleDeStaking.css';

function ButtonPeriods({list,poolApy, timeperiod}) {
  console.log(timeperiod);
  return(
        <>
        <div className="row justify-content-md-start staking-panel-button-margin">
            
        {list ? 
        list.map((item, idx) => {
            return (
            <div key={idx} className='col-xxl-3 col-xl-4 col-6 pe-xl-4 mb-2'>
                {
                    timeperiod == idx ? (
                        <button 
                            type="button" 
                            className="btn btn-kronobit btn-lg rounded-pill text-small w-100 month active"
                            onClick={() => {
                                poolApy(idx);
                            }}>
                        {item.label}
                        </button>
                    ): (
                        <button 
                            type="button" 
                            className="btn btn-kronobit btn-lg rounded-pill text-small w-100 month"
                            onClick={() => {
                                poolApy(idx);
                            }}>
                        {item.label}
                        </button>
                    )
                }
                
            </div>
            )}) 
        :
        ""}

        </div>
        </>
        
      
    );
}
export default ButtonPeriods;