
import AbiToken from "../abi/AbiToken.json";
import AbiStakingPool from "../abi/AbiStakingPool.json";
export const walletProvider = {
    METAMASK: 'metaMask',
    TRUSTWALLET: 'trustWallet',
    SAFEPAL: 'safePal',
    WALLET_CONNECT: 'walletConnect'
};

//TESTNET
export const RPC_NODE_TESTNET={
    http:"https://mainnet-rpc.zedscan.net/",
    wss:"wss://mainnet-rpc.zedscan.net/ws",
}

export const RPC_NODE={
    http:"https://mainnet-rpc.zedscan.net",
    wss:"wss://mainnet-rpc.zedscan.net/ws",
}

export const TOKEN_STAKE ={
    address:'0x3F7BE9DB9d37bd89c3Fa722BB29bA17300C18fc8',
    abi: AbiToken
}

export const POOL_STAKE ={
    address:'0xb265133bF92b489622e2Fb0022e6B7E4B2F55d73',
    abi: AbiStakingPool
}